<template>
  <v-app class>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div>
              <v-alert v-model="alert"
                       border="left"
                       dark
                       dismissible
                       class="alert"
                       :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
              </v-alert>
            </div>
            <div class="col-md-11 text-center">
              <h1>Union Membership Configuration</h1>
            </div>
          </div>
          <div class="col-md-6 ml-auto mr-auto">
            <v-card class="mx-auto my-12">
                <v-form ref="UnionMembershipForm" v-model="valid">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12">
                            <TextField v-model="formData.name" :label="'Name'" />
                            </v-col>
                            <v-col cols="12">
                            <TextField v-model="formData.description" :label="'Description'" />
                            </v-col>
                            <v-col cols="12">
                            <TextField v-model="formData.markup" :type="'number'" :label="'Mark Up'" />
                            </v-col>
                        </v-row>
                        <v-col cols="9 mt-5" class="d-flex justify-end">
                            <Button
                            :label="'Submit'"
                            :btnType="'Submit'"
                            @onClick="dialog = true"
                            :disabled="!valid"
                            :isLoading="loadingSubmit"
                            class="mr-4"
                            />
                            <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                        </v-col>
                    </v-card-text>
                </v-form>
            </v-card>
          </div>
          <div v-if="loadingData"  class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
           <v-data-table v-else
                          loading-text="Loading data. Please wait...."
                          :loading="!unionMembership"
                         :headers='headers'
                         :items="unionMembership"
                         item-key="name">
                          <template v-slot:[`item.approved`]="{ item }" >
                      <span v-if="item.approved === true">Approved</span>
                      <span v-if="item.approved === false">Pending</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex">
                        <Button :btnType="'Submit'" :label="'Edit'" @onClick="edit(item)" class="mr-2" />
                        <Button :btnType="'Cancel'" :label="'Delete'" @onClick="delItem(item)" class="mr-2" />
                    </div>
                    </template>
           </v-data-table>
            <Dialog ref="UnionMembershipForm" :title="'Edit Union Membership'" :width="700">
                <v-row>
                    <TextField v-model="editedData.name" :label="'name'"/>
                </v-row>
                <v-row>
                    <TextField v-model="editedData.description" :label="'description'"/>
                </v-row>
                <v-row>
                    <TextField v-model="editedData.markup" :label="'markup'"/>
                </v-row>
                <template v-slot:footer>
                <Button :btnType="'Submit'" :label="'Update'" @onClick="update" :isLoading="loading" class="mr-2"/>
                <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel" />
                </template>
            </Dialog>
        </v-container>
      </div>
    </div>

    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loadingSubmit" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>

    <ConfirmationDialog :title="'Are you sure you want to delete?'" :isLoading="loadingDel" :dialog="dialogDel" :btnTitle="'Yes'" @close="dialogDel = false" @btnAction="onDelete"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { unionMemberService } from '@/services'
import TextField from '@/components/ui/form/TextField'
export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog,
    Dialog
  },
  data () {
    return {
      formData: {
        id: 0,
        name: '',
        decription: '',
        markup: ''
      },
      editedData: {
        id: 0,
        name: '',
        description: '',
        markup: 0
      },

      pay: [],
      approval: [],
      loadingData: false,
      loadingDel: false,
      loading: false,
      dialog: false,
      dialogApprove: false,
      loadingSubmit: false,
      dialogDel: false,
      valid: false,
      unionMembership: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Decription',
          value: 'description'
        },
        {
          text: 'MarkUp',
          value: 'markUp'
        },
        {
          text: 'Approved',
          value: 'approved'
        },
        {
          text: 'Actions',
          value: 'actions'
        },
        {
          text: 'Approved By',
          value: 'approvedBy'
        }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },

    getUnionMembership () {
      this.loadingData = true
      unionMemberService.getUnionMemberships().then(result => {
        this.unionMembership = result.data
      }).catch(error => {
        console.log(error)
      })
      this.loadingData = false
    },

    onSave () {
      this.loadingSubmit = true
      console.log('Ok')
      const data = {
        Name: this.formData.name,
        Description: this.formData.description,
        MarkUp: parseFloat(this.formData.markup)
      }
      unionMemberService.addUnionMembership(data).then(() => {
        this.showAlertMessage('Union Membership successfully Added', 'success')
        this.loadingSubmit = false
        this.dialog = false
        this.getUnionMembership()
      }).catch((error) => {
        console.log('Error: ', error)
        this.showAlertMessage('Union Membership failed', 'error')
        this.loadingSubmit = false
        this.dialog = false
        this.getUnionMembership()
      }).finally(() => {
        this.loadingSubmit = false
        this.dialog = false
        this.onReset()
      })
    },
    edit (item) {
      this.editedData.id = item.id
      this.editedData.name = item.name
      this.editedData.markup = parseFloat(item.markUp)
      this.editedData.description = item.description
      this.$refs.UnionMembershipForm.toggleModal()
    },
    delItem (item) {
      this.pay = item
      this.dialogDel = true
    },
    onDelete () {
      this.loadingDel = true
      unionMemberService.deleteUnionMembership(this.pay).then(() => {
        this.showAlertMessage('Income Tax Band successfully Deleted', 'success')
        this.loadingDel = false
        this.dialogDel = false
        this.getUnionMembership()
      }).catch(error => {
        console.log(error)
        this.loadingDel = false
      })
    },
    update () {
      this.loading = true
      const data = {
        Id: this.editedData.id,
        Name: this.editedData.name,
        Description: this.editedData.description,
        MarkUp: this.editedData.markup
      }
      unionMemberService.updateUnionMembership(data).then(() => {
        this.showAlertMessage('Union Membership successfully Updated', 'success')
        this.loading = false
        this.getUnionMembership()
        this.cancel()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    cancel () {
      this.$refs.UnionMembershipForm.toggleModal()
    },
    onReset () {
      this.$refs.UnionMembershipForm.reset()
      this.formData.name = ''
      this.formData.description = ''
      this.formData.markup = ''
    }
  },
  mounted () {
    this.getUnionMembership()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }
  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
